import React from 'react';

import {
    Footer,
} from 'reaxl-footer-ford';

export default function FordFooter() {
    const fordLogo = {
        src: 'https://www.autotrader.com/content/static/img/partner/ford/ford-footer-logo.svg',
        alt: 'Ford Blue Advantage logo',
        width: '150',
        height: '41',
    };
    const atcFordLogo = {
        src: 'https://www.autotrader.com/content/static/img/partner/ford/ford-footer-atc-logo.svg',
        alt: 'Autotrader logo',
        width: '150',
        height: '41',
    };

    return (
        <Footer
            atcFordLogo={atcFordLogo}
            fordLogo={fordLogo}
        />
    );
}
